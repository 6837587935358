import jwt_decode from 'jwt-decode'
import { SettingsManager } from '../../../configuration/SettingsManager'
import { SessionTokenTokenNbfInFutureError, SessionTokenExpiredError, SessionTokenStaleError } from '../JwtStorage/contracts'

/**
 * represents the user's current session, valid or invalid
 *
 * @export
 * @class UserSession
 */
export class UserSession {
	/**
	 * jwt issued at date
	 *
	 * @type {Date}
	 * @memberof UserSession
	 */
	readonly iat: Date
	/**
	 * jwt not before date
	 *
	 * @type {Date}
	 * @memberof UserSession
	 */
	readonly nbf: Date
	/**
	 * jwt expirtation date
	 *
	 * @type {Date}
	 * @memberof UserSession
	 */
	readonly exp: Date
	/**
	 * we consider "mid" to be the halfway point between nbf and exp. when the mid date is in the past the session is considered "stale"
	 * stale sessions are refreshed with the API server along with subsequent calls
	 *
	 * @type {Date}
	 * @memberof UserSession
	 */
	readonly mid: Date
	/**
	 * jwt subject
	 *
	 * @type {string}
	 * @memberof UserSession
	 */
	readonly sub: string

	constructor(iat: Date, nbf: Date, exp: Date, mid: Date, sub: string) {
		this.iat = iat
		this.exp = exp
		this.nbf = nbf
		this.mid = mid
		this.sub = sub
	}

	/**
	 * create a "null" session AKA null-object pattern
	 *
	 * @static
	 * @returns {UserSession}
	 * @memberof UserSession
	 */
	static getUserSessionNull(): UserSession {
		return new UserSession(new Date(0), new Date(0), new Date(0), new Date(0), '')
	}

	/**
	 * returns true when this is "null"
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof UserSession
	 */
	get isNull(): boolean {
		return this.iat.getTime() === new Date(0).getTime() && this.exp.getTime() === new Date(0).getTime() && this.nbf.getTime() === new Date(0).getTime() && this.mid.getTime() === new Date(0).getTime() && this.sub === ''
	}

	/**
	 * generate a UserSession which is close to expiration
	 *
	 * @static
	 * @returns {UserSession}
	 * @memberof UserSession
	 */
	static getUserSessionNearExpiration(): UserSession {
		const secondsUntilWarning = SettingsManager.REACT_APP_SECONDS_BEFORE_EXPIRATION_WARNING().value
		return new UserSession(new Date(Date.now() - secondsUntilWarning * 1000), new Date(Date.now() - secondsUntilWarning * 1000), new Date(Date.now() + secondsUntilWarning * 1000), new Date(Date.now() + (secondsUntilWarning / 2) * 1000), 'Something')
	}

	/**
	 * generate a UserSession which is expired
	 *
	 * @static
	 * @returns {UserSession}
	 * @memberof UserSession
	 */
	static getUserSessionExpired(): UserSession {
		return new UserSession(new Date(Date.now()), new Date(Date.now()), new Date(Date.now()), new Date(Date.now()), 'Something')
	}

	/**
	 * generate a UserSession from a jwt.
	 * if the supplied jwt is null then a "null object" UserSession is returned
	 * if the jwt is invalid format and cannot be decoded an exception will be thrown
	 * note that no validation of the actual decoded values is performed within this method
	 *
	 * @static
	 * @param {(string | null)} jwt
	 * @returns {UserSession}
	 * @memberof UserSession
	 */
	static getUserSessionFromJwt(jwt: string | null): UserSession {
		if (jwt == null) {
			return UserSession.getUserSessionNull()
		}
		const decoded: any = jwt_decode(jwt)
		const fieldfn = (fieldName: string): string => {
			const value = String(decoded[fieldName])
			if (value == null) {
				throw new Error(`jwt missing ${fieldName}: ${JSON.stringify(decoded)}`)
			}
			return value
		}
		return new UserSession(new Date(parseInt(fieldfn('iat')) * 1000), new Date(parseInt(fieldfn('nbf')) * 1000), new Date(parseInt(fieldfn('exp')) * 1000), new Date((parseInt(fieldfn('nbf')) * 1000 + parseInt(fieldfn('exp')) * 1000) / 2), fieldfn('sub'))
	}

	readonly isNbfAfterNow = (): boolean => new Date() < this.nbf
	readonly isMidBeforeNow = (): boolean => new Date() > this.mid
	readonly isExpBeforeNow = (): boolean => new Date() > this.exp
	readonly secondsUntilExpired = () => Math.round((this.exp.getTime() - new Date().getTime()) / 1000)
	readonly secondsUntilStale = () => Math.round((this.mid.getTime() - new Date().getTime()) / 1000)

	/**
	 * basic client-side sanity checks on a user session jwt to * ensure the jwt is usable for the front-end
	 *
	 * @memberof UserSession
	 */
	public validate(): void {
		if (this.isNbfAfterNow()) {
			throw new SessionTokenTokenNbfInFutureError(`nbf was in future: ${String(this.nbf)} date now is ${String(new Date())} ${this.nbf.getTime()} > ${new Date().getTime()}`)
		} else if (this.isExpBeforeNow()) {
			throw new SessionTokenExpiredError(`exp was in past: ${String(this.exp)}`)
		} else if (this.isMidBeforeNow()) {
			throw new SessionTokenStaleError(`token considered stale. midpoint was in past: ${String(this.mid)}`)
		}
	}
}
