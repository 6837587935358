import { injectable } from 'inversify'
import { UserSession } from '../../services/UserSessionStateManager/UserSession'
import { injectProperty } from '../../configuration/ServiceLocator'
import { IJwtStorage } from '../../services/JwtStorage/contracts'
import { IUserSessionApiClient } from '../../services/UserSessionApiClient/contracts'
import { IUserSessionMiddleware } from './contracts'
import { SettingsManager } from '../../../configuration/SettingsManager'
import jwt_decode from 'jwt-decode'

@injectable()
export class UserSessionMiddleware implements IUserSessionMiddleware {
	@injectProperty('IJwtStorage')
	jwtStorage!: IJwtStorage

	@injectProperty('IUserSessionApiClient')
	userSessionApiClient!: IUserSessionApiClient

	loadSessionJwt(): Promise<string> {
		// attempt to load the session token from storage. if it is missing or expired perform login, store/return the result
		const currentSessionJwt = this.jwtStorage.getJwt()

		let isSessionExpired = false

		const ssoToken = localStorage.getItem(SettingsManager.REACT_APP_SSO_TOKEN_NAME)

		if (ssoToken !== null) {
			return this.userSessionApiClient.getSessionJwtUsingSsoJwt(ssoToken).then(sessionJwt => {
				this.jwtStorage.saveJwt(sessionJwt)
				localStorage.removeItem(SettingsManager.REACT_APP_SSO_TOKEN_NAME)
				SettingsManager.setProductLocalStorage(sessionJwt)

				return new Promise<string>(resolve => resolve(sessionJwt))
			})
		} else {
			try {
				isSessionExpired = UserSession.getUserSessionFromJwt(currentSessionJwt).isExpBeforeNow()
			} catch {}
			if (currentSessionJwt === null || isSessionExpired) {
				console.info('No session active session token found. Attempting to load SSO token to exchange for new session.')
			}
		}

		// at this point the session token was found in storage and not expired.
		// if it is more than half-expired then perform refresh and store/return the result
		// otherwise just return the token
		const currentUserSession = UserSession.getUserSessionFromJwt(currentSessionJwt)
		if (currentUserSession.isMidBeforeNow()) {
			return this.userSessionApiClient
				.refreshSessionJwt(currentSessionJwt)
				.then(sessionJwt => {
					this.jwtStorage.saveJwt(sessionJwt)
					SettingsManager.setProductLocalStorage(sessionJwt)
					return new Promise<string>(resolve => resolve(sessionJwt))
				})
				.catch(error => {
					return new Promise<string>(resolve => resolve(''))
				})
		}
		if (currentUserSession.isNbfAfterNow()) {
			// session token found but invalid because the NBF is in the future. This MAY happen due to clock skew with client machines!
			// if the NBF is before now from the perspective of the client then we issue a warning on the console and continue
			console.warn(`session token NBF is ${currentUserSession.nbf} but the current time is ${new Date()} which means NBF is in the future.`)
			SettingsManager.setProductLocalStorage(currentSessionJwt)
			return new Promise<string>(resolve => resolve(currentSessionJwt == null ? '' : currentSessionJwt))
		}

		SettingsManager.setProductLocalStorage(currentSessionJwt)
		return new Promise<string>(resolve => resolve(currentSessionJwt == null ? '' : currentSessionJwt))
	}

	ifPermissionExists(requiredPermission: number): Promise<boolean | void> {
		return this.loadSessionJwt()
			.then(response => {
				const sessionToken = response
				if (sessionToken !== null) {
					let tokenVal: any
					tokenVal = jwt_decode(sessionToken)
					if (tokenVal !== null && typeof tokenVal !== 'undefined') {
						if (tokenVal.permissions !== null && typeof tokenVal.permissions !== 'undefined') {
							return tokenVal.permissions.indexOf(requiredPermission) > -1
						}
					}
				} else {
					return false
				}
			})
			.catch(error => {
				console.log(error)
				return false
			})
	}
}
