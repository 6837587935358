import React from 'react'
import { SettingsManager } from '../../../../configuration/SettingsManager'

export const DebugSettingsManager: React.FunctionComponent = () => {
	return (
		<table className="table table-dark table-hover w-100">
			<thead>
				<tr className="d-flex">
					<th className="col-2">SettingName</th>
					<th className="col-3">Description</th>
					<th className="col-1">Default Val</th>
					<th className="col-1">Actual Val</th>
				</tr>
			</thead>
			<tbody>
				{Reflect.ownKeys(SettingsManager)
					.filter(x => x.toString().startsWith('REACT_APP'))
					.sort()
					.map(x => (SettingsManager as any)[String(x)]())
					.map(k => {
						return (
							<tr key={k.name} className="d-flex">
								<td className="col-2">{k.name}</td>
								<td className="col-3">{k.description}</td>
								<td className="col-1">{k.defaultValue}</td>
								<td className="col-1" style={{ wordWrap: 'break-word' }}>
									{String(k.value)}
								</td>
							</tr>
						)
					})}
			</tbody>
		</table>
	)
}
