import { IUserSessionApiClient } from './contracts'
import { SettingsManager } from '../../../configuration/SettingsManager'
import { injectable } from 'inversify'

const axios = require('axios').default

@injectable()
export class UserSessionApiClient implements IUserSessionApiClient {
	getSessionJwtUsingSsoJwt(ssoJwt: string | null): Promise<string> {
		const date = new Date()
		return axios
			.get(`${SettingsManager.REACT_APP_USER_SESSION_API_BASE_URL().value}/auth/login?t=` + date.getTime(), {
				headers: {
					Authorization: 'Bearer ' + ssoJwt,
				},
			})
			.then((response: { data: any }) => {
				return response.data.token
			})
			.catch((error: any) => {
				console.error(`Request to login failed. More information: ${JSON.stringify(error)}`)
				throw new Error(`Error returned from attempt to authenticate: ${error.message}`)
			})
	}

	refreshSessionJwt(sessionJwt: string | null): Promise<string> {
		const date = new Date()
		// calling myAbility Portal method keepalive in the HomeController to keep the session alive for portal header/footer
		if (SettingsManager.REACT_APP_MYABILITY_ROOT_URL().value) {
			axios.get(`${SettingsManager.REACT_APP_MYABILITY_ROOT_URL().value}/home/keepalive?t = ` + date.getTime())
		}
		return axios
			.get(`${SettingsManager.REACT_APP_USER_SESSION_API_BASE_URL().value}/auth/refresh?t = ` + date.getTime(), {
				headers: {
					Authorization: 'Bearer ' + sessionJwt,
				},
			})
			.then((response: { data: any }) => {
				return response.data.token
			})
			.catch((error: any) => {
				console.error(`Request to refresh session failed. More information: ${JSON.stringify(error)}`)
				//throw new Error(`Error returned from attempt to authenticate: ${ error.message }`)
				throw error
			})
	}
}
