import { injectable } from 'inversify'
import { IJwtStorage } from './contracts'

/**
 * base class for session storage (JWT-driven)
 *
 * @export
 * @abstract
 * @class BaseJwtStorage
 * @implements {IJwtStorage}
 */
@injectable()
export abstract class BaseJwtStorage implements IJwtStorage {
	abstract saveJwt(sessionJwt: string): void
	abstract clear(): void
	abstract getJwt(): string | null
}
