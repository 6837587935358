import React from 'react'

export const DebugEnvironmentVariables: React.FunctionComponent = () => {
	return (
		<ul className="list-group">
			{Object.keys(process.env).map(k => {
				return (
					<li key={k} className="list-group-item list-group-item-info">
						{k}={process.env[k]}
					</li>
				)
			})}
		</ul>
	)
}
