import { injectable } from 'inversify'
import { SettingsManager } from '../../../configuration/SettingsManager'
import { IUserSessionApiClient } from './contracts'

/**
 * Returns the HardCodedSessionJwt with any call
 *
 * @export
 * @class MockUserSessionApiClient
 * @implements {IUserSessionApiClient}
 */
@injectable()
export class MockUserSessionApiClient implements IUserSessionApiClient {
	getSessionJwtUsingSsoJwt = (ssoJwt: string | null): Promise<string> =>
		new Promise(resolve => {
			setTimeout(() => {
				resolve(SettingsManager.REACT_APP_HARD_CODED_SSO_JWT().value)
			}, 100)
		})

	refreshSessionJwt = (sessionJwt: string | null): Promise<string> =>
		new Promise(resolve => {
			setTimeout(() => {
				resolve(SettingsManager.REACT_APP_HARD_CODED_SESSION_JWT().value)
			}, 100)
		})
}
