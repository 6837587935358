import { injectable } from 'inversify'
import { BaseJwtStorage } from './BaseJwtStorage'
import { SettingsManager } from '../../../configuration/SettingsManager'

/**
 * implementation of jwt storage which uses localstorage
 *
 * @export
 * @class LocalJwtStorage
 * @extends {BaseJwtStorage}
 */
@injectable()
export class LocalJwtStorage extends BaseJwtStorage {
	public readonly saveJwt = (sessionJwt: string): void => localStorage.setItem(SettingsManager.REACT_APP_SESSION_TOKEN_NAME, sessionJwt)
	public readonly clear = (): void => localStorage.removeItem(SettingsManager.REACT_APP_SESSION_TOKEN_NAME)
	public readonly getJwt = (): string | null => localStorage.getItem(SettingsManager.REACT_APP_SESSION_TOKEN_NAME)
}
