import React, { ErrorInfo, ReactElement } from 'react'
import { Alert } from 'reactstrap'

export interface IResponsePanelProps {
	children?: ReactElement
}

export interface IResponsePanelState {
	infoMessage: string
	warnMessage: string
	isInfoVisible: boolean
	isWarnVisible: boolean
}

export class ResponsePanel extends React.Component<IResponsePanelProps, IResponsePanelState> {
	constructor(props: any) {
		super(props)
		this.state = {
			infoMessage: '',
			warnMessage: '',
			isInfoVisible: false,
			isWarnVisible: false,
		}
	}

	static getDerivedStateFromError(error: Error): IResponsePanelState {
		return {
			infoMessage: '',
			warnMessage: String(error),
			isInfoVisible: false,
			isWarnVisible: true,
		}
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {}

	render() {
		if (this.state.isWarnVisible) {
			return (
				<Alert color="warning" isOpen={this.state.isWarnVisible} toggle={() => this.setState({ isWarnVisible: false })}>
					{this.state.warnMessage}
				</Alert>
			)
		} else {
			return (
				<div>
					<Alert color="info" isOpen={this.state.isInfoVisible} toggle={() => this.setState({ isInfoVisible: false })}>
						{this.state.infoMessage}
					</Alert>
					{this.props.children}
				</div>
			)
		}
	}
}
