import React from 'react'
import { SettingsManager } from '../../../configuration/SettingsManager'
import { ModalHeader, ModalBody, Button, ModalFooter, Alert, Modal } from 'reactstrap'
import { IUserSessionStateManager } from '../../services/UserSessionStateManager/contracts'
import { container } from '../../configuration/ServiceLocator'
import { UserSession } from '../../services/UserSessionStateManager/UserSession'

export interface IUserSessionState {
	isSessionExpirationModalVisible: boolean
	isSessionExpiredModalVisible: boolean
	sessionExpirationCountdown: string
}

export interface IUserSessionProps {
	userSessionStore: IUserSessionStateManager
}

export class UserSessionViewClass extends React.Component<IUserSessionProps, IUserSessionState> {
	constructor(props: IUserSessionProps) {
		super(props)
		this.state = {
			isSessionExpirationModalVisible: false,
			isSessionExpiredModalVisible: false,
			sessionExpirationCountdown: '',
		}

		this.props.userSessionStore.loadSessionJwt()
		const _this = this
		// every second check the token's expiry and determine if warning/error dialogs should be displayed
		setInterval(function() {
			const sessionTokenVal = localStorage.getItem(SettingsManager.REACT_APP_SESSION_TOKEN_NAME)
			const currentUserSession = UserSession.getUserSessionFromJwt(sessionTokenVal)

			const totalSecondsUntilExpiration = (currentUserSession.exp.getTime() - new Date().getTime()) / 1000
			const totalSecondsUntilWarningPopup = totalSecondsUntilExpiration - SettingsManager.REACT_APP_SECONDS_BEFORE_EXPIRATION_WARNING().value
			const minutes = Math.floor(totalSecondsUntilExpiration / 60)
			const seconds = Math.floor(totalSecondsUntilExpiration % 60)
			const padLeft = (num: number, length: number): string => {
				return Array(Math.max(String(num).length, length) - String(num).length + 1).join('0') + num
			}
			_this.setState({
				isSessionExpirationModalVisible: totalSecondsUntilExpiration > 0 && totalSecondsUntilWarningPopup < 0,
				isSessionExpiredModalVisible: totalSecondsUntilExpiration < 0 && _this.props.userSessionStore.userSession.exp.getTime() > 0,
				sessionExpirationCountdown: `You will be logged out in ${padLeft(minutes, 2)}:${padLeft(seconds, 2)}`,
			})
		}, 1000)
	}

	onLogout(event: any): void {
		event.preventDefault()
		this.props.userSessionStore.logOut()
	}

	onTokenRefresh(event: any): void {
		event.preventDefault()
		this.props.userSessionStore.loadSessionJwt().catch(error => alert(error))
	}

	render() {
		return (
			<div>
				<Modal isOpen={this.state.isSessionExpirationModalVisible} fade={false}>
					<ModalHeader>Session expiration warning</ModalHeader>
					<ModalBody>
						<Alert color="danger">Your session is about to expire.</Alert>
						<p className="mx-auto"> {this.state.sessionExpirationCountdown} </p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={e => this.props.userSessionStore.loadSessionJwt()}>
							Continue
						</Button>
						<Button className="btn-LogOut" onClick={e => this.onLogout(e)}>
							Log Out
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.isSessionExpiredModalVisible} fade={false}>
					<ModalHeader>Session expired</ModalHeader>
					<ModalBody>
						<Alert color="danger">Your session has expired.</Alert>
					</ModalBody>
					<ModalFooter>
						<Button className="btn-LogOut" onClick={e => this.onLogout(e)}>
							Log Out
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

export const UserSessionConnected = () => {
	const store = container.get<IUserSessionStateManager>('IUserSessionStateManager')
	return <UserSessionViewClass userSessionStore={store} />
}
