import React from 'react'
import './DebugPanel.scss'
import { DebugEnvironmentVariables } from './DebugEnvironmentVariables/DebugEnvironmentVariables'
import { DebugSettingsManager } from './DebugSettingsManager/DebugSettingsManager'
import { SettingsManager } from '../../../configuration/SettingsManager'
import { DebugUserSessionConnected } from './DebugUserSession/DebugUserSession'
import { TabContent, TabPane } from 'reactstrap'

export interface IDebugPanelState {
	// controls the visibility of the debug panel
	isPanelCollapsed: boolean
	// controls the tab which is selected within the debug panel
	selectedTabId: string
}

export interface IDebugPanelProps {}

export class DebugPanel extends React.Component<IDebugPanelProps, IDebugPanelState> {
	constructor(props: IDebugPanelProps) {
		super(props)
		this.state = {
			isPanelCollapsed: true,
			selectedTabId: 'settingsmanager',
		}
	}

	onToggleCollapse(event: any) {
		event.preventDefault()
		this.setState({ isPanelCollapsed: !this.state.isPanelCollapsed })
	}

	render() {
		if (!SettingsManager.REACT_APP_IS_DEBUG_PANEL_VISIBLE().value) {
			return null
		} else {
			return (
				<div className="debug-panel">
					<div className="debug-panel-toggle">
						<button
							className="btn btn-warning btn-sm"
							onClick={e => {
								this.onToggleCollapse(e)
							}}
						>
							Debug +/-
						</button>
					</div>
					{!this.state.isPanelCollapsed && (
						<div className="row-fluid debug-panel-content">
							<ul className="nav nav-tabs">
								<li className="nav-item">
									<button onClick={() => this.setState({ selectedTabId: 'settingsmanager' })} className={this.state.selectedTabId === 'settingsmanager' ? 'nav-link active' : 'nav-link'}>
										SettingsManager
									</button>
								</li>
								<li className="nav-item">
									<button onClick={() => this.setState({ selectedTabId: 'session' })} className={this.state.selectedTabId === 'session' ? 'nav-link active' : 'nav-link'}>
										Session
									</button>
								</li>
								<li className="nav-item">
									<button onClick={() => this.setState({ selectedTabId: 'environment' })} className={this.state.selectedTabId === 'environment' ? 'nav-link active' : 'nav-link'}>
										Environment
									</button>
								</li>
							</ul>
							<TabContent activeTab={this.state.selectedTabId}>
								<TabPane tabId="environment">
									<DebugEnvironmentVariables />
								</TabPane>
								<TabPane tabId="settingsmanager">
									<DebugSettingsManager />
								</TabPane>
								<TabPane tabId="session">
									<DebugUserSessionConnected />
								</TabPane>
							</TabContent>
						</div>
					)}
				</div>
			)
		}
	}
}
