/**
 * represents a collection of validation responses resulting
 * from attempts to validate the values of the current state.
 * this may become a higher-level reusable concept in future revs.
 *
 * @class ValidationResult
 */
export class ValidationResult {
	/**
	 * validation results are a collection of keyvalue pairs
	 * keys are strings related to the field name being validated
	 * values are validation error messages
	 *
	 * @type {{[key: string]: string}}
	 * @memberof ValidationResult
	 */
	results: { [key: string]: string }

	constructor() {
		this.results = {}
	}

	/**
	 * add a keyvalue pair signifying a validation error for a specific field
	 *
	 * @param {string} fieldName
	 * @param {string} message
	 * @memberof ValidationResult
	 */
	setMessage(fieldName: string, message: string) {
		this.results[fieldName] = message
	}

	/**
	 * return true if a given field by name is valid
	 *
	 * @param {string} fieldName
	 * @returns {boolean}
	 * @memberof ValidationResult
	 */
	isFieldValid(fieldName: string): boolean {
		return this.results[fieldName] == null
	}

	/**
	 * return true of all fields are valid
	 *
	 * @returns {boolean}
	 * @memberof ValidationResult
	 */
	isAllFieldsValid(): boolean {
		return Object.keys(this.results).length === 0
	}

	/**
	 * get the validation model for a specific field
	 *
	 * @param {string} fieldName
	 * @returns
	 * @memberof ValidationResult
	 */
	getMessage(fieldName: string) {
		return this.results[fieldName]
	}
}
