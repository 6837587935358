import { Container, interfaces } from 'inversify'
//reflect-metadata should be imported before any interface or other imports
//also it should be imported only once so that a singleton is created.
import 'reflect-metadata'
import getDecorators from 'inversify-inject-decorators'

// the container is created once, witin this module and used throughout the application
export const container = new Container()

// once we create a container we add capability to support lazy injection via decorators
// in our version of ECMASCRIPT decorators are not fully supported and the following code ensures
// lazy inject decorators will work as expected.
// https://github.com/inversify/InversifyJS/issues/1026
// more information: https://itnext.io/a-minimal-guide-to-ecmascript-decorators-55b70338215e
const decorators = getDecorators(container, false)
interface IBabelPropertyDescriptor extends PropertyDescriptor {
	initializer(): any
}

/**
 * the @injectProperty decorator allows us to lazily inject dependencies into react components at the time of access
 * at the time of this writing react does not support the traditional constructor-level injection
 * @export
 * @param {interfaces.ServiceIdentifier<any>} serviceIdentifier
 * @returns
 */
export const injectProperty = function(serviceIdentifier: interfaces.ServiceIdentifier<any>) {
	const original = decorators.lazyInject(serviceIdentifier)
	// the 'descriptor' parameter is actually always defined for class fields for Babel, but is considered undefined for TSC
	// so we flag this as volatile with ?/! combination to avoid "TS1240: Unable to resolve signature of property decorator when called as an expression"
	return function(this: any, proto: any, key: string, descriptor?: IBabelPropertyDescriptor): void {
		// make it work as usual
		original.call(this, proto, key)
		// return link to proto, so own value wont be 'undefined' after component's creation
		descriptor!.initializer = function() {
			return proto[key]
		}
	}
}
