import React from 'react'
import { IUserSessionStateManager } from '../../../services/UserSessionStateManager/contracts'
import { container } from '../../../configuration/ServiceLocator'
import { Button } from 'reactstrap'

export interface IDebugUserSessionState {
	// the number of seconds until token is stale is constantly updated on the UI using a timer function
	totalSecondsUntilSessionTokenStale: number
	// the number of seconds until token is expired is constantly updated on the UI
	totalSecondsUntilSessionTokenExpired: number
}

export interface IDebugUserSessionProps {
	userSessionStore: IUserSessionStateManager
}

export class DebugUserSession extends React.Component<IDebugUserSessionProps, IDebugUserSessionState> {
	constructor(props: IDebugUserSessionProps) {
		super(props)
		this.state = {
			totalSecondsUntilSessionTokenExpired: 0,
			totalSecondsUntilSessionTokenStale: 0,
		}
		const _this = this
		// every few seconds re-evaluate the state of the token stale/expiry in state
		setInterval(function() {
			const now = new Date().getTime()
			_this.setState({
				totalSecondsUntilSessionTokenStale: Math.round((_this.props.userSessionStore.userSession.mid.getTime() - now) / 1000),
				totalSecondsUntilSessionTokenExpired: Math.round((_this.props.userSessionStore.userSession.exp.getTime() - now) / 1000),
			})
		}, 5000)
	}

	render() {
		return (
			<div>
				<ul className="list-group">
					<li className="list-group-item">
						<p>Session Token details:</p>
						{this.props && (
							<ul>
								<li>IAT: {String(this.props.userSessionStore.userSession.iat)}</li>
								<li>NBF: {String(this.props.userSessionStore.userSession.nbf)}</li>
								<li>MID: {String(this.props.userSessionStore.userSession.mid)}</li>
								<li>EXP: {String(this.props.userSessionStore.userSession.exp)}</li>
								<li>SUB: {String(this.props.userSessionStore.userSession.sub)}</li>
							</ul>
						)}
					</li>
					<li className={this.state.totalSecondsUntilSessionTokenStale <= 0 ? 'list-group-item list-group-item-warning' : 'list-group-item list-group-item-success'}>Token becomes stale in {this.state.totalSecondsUntilSessionTokenStale} seconds</li>
					<li className={this.state.totalSecondsUntilSessionTokenExpired <= 0 ? 'list-group-item list-group-item-danger' : 'list-group-item list-group-item-success'}>Token expires in {this.state.totalSecondsUntilSessionTokenExpired} seconds</li>
					<li className="list-group-item">
						<Button color="primary" onClick={() => this.props.userSessionStore.loadSessionJwt()}>
							Load the token from storage (refresh if stale)
						</Button>
						<Button color="primary" onClick={() => this.props.userSessionStore.simulateTokenNearExpiration()}>
							Simulate near expiration
						</Button>
						<Button color="primary" onClick={() => this.props.userSessionStore.simulateTokenExpired()}>
							Simulate expired
						</Button>
					</li>
				</ul>
			</div>
		)
	}
}

export const DebugUserSessionConnected = () => {
	const store = container.get<IUserSessionStateManager>('IUserSessionStateManager')
	return <DebugUserSession userSessionStore={store} />
}
