import { container } from '../configuration/ServiceLocator'
import { IUserSessionApiClient } from '../services/UserSessionApiClient/contracts'
import { MockUserSessionApiClient } from '../services/UserSessionApiClient/MockUserSessionApiClient'
import { IJwtStorage } from '../services/JwtStorage/contracts'
import { HardCodedJwtStorage } from '../services/JwtStorage/HardCodedJwtStorage'
import { LocalJwtStorage } from '../services/JwtStorage/LocalStorageJwtSessionStorage'
import { IUserSessionStateManager } from '../services/UserSessionStateManager/contracts'
import { UserSessionStateManager } from '../services/UserSessionStateManager/UserSessionStateManager'
import { UserSessionApiClient } from '../services/UserSessionApiClient/UserSessionApiClient'
import { IUserSessionMiddleware } from '../middleware/UserSessionMiddleware/contracts'
import { UserSessionMiddleware } from '../middleware/UserSessionMiddleware/UserSessionMiddleware'

/**
 * This module centralizes all configurable application settings into a single public object with documentation
 * Settings are loaded on demand through strongly-typed properties
 * Settings can be configured at build-time via use of .env files or at startup-time via appConfig.js
 */

/**
 * Represents a single entry within SettingsManager
 *
 * @export
 * @interface ISettingsManagerSetting
 */
export interface ISettingsManagerSetting<T extends boolean | number | string> {
	name: string
	defaultValue?: T
	value: T
	description: string
}

/**
 * the prototype for the singleton SettingsManager instance
 *
 * @class SettingsManagerPrototype
 */
export class BaseSettingsManager {
	/**
	 * Load a setting in a string format.
	 *
	 * @param {string} settingName expected to be PascalCase format
	 * @param {string} description description for the setting (for display in debug panel)
	 * @param {string} [defaultValue] optional default value if setting not found. when not supplied a setting must be explicitly defined
	 * @returns {ISettingsManagerSetting}
	 * @memberof SettingsManager
	 */
	static getSettingAsString = (settingName: string, description: string, defaultValue?: string): ISettingsManagerSetting<string> => {
		const windowVariable = ((window as any).ENV || {})[settingName]
		const envVariable = process.env[settingName]
		// top priority: global (window) variables set in public/appConfig.js loaded dynamically
		// second priority: env vars set at time of build
		// third priority: default values (if available)
		const val = String(windowVariable || envVariable || (defaultValue == null ? undefined : defaultValue))
		if (val !== 'undefined') {
			return {
				name: settingName,
				defaultValue,
				value: val,
				description,
			}
		}

		throw new Error(`The setting [${settingName}] was requested but the value was not found and no default was supplied.`)
	}

	/**
	 * Load a setting in a boolean format.
	 *
	 * @param {string} settingName expected to be PascalCase format
	 * @param {string} description description for the setting (for display in debug panel)
	 * @param {boolean} [defaultValue] optional default value if setting not found. when not supplied a setting must be explicitly defined
	 * @returns {ISettingsManagerSetting}
	 * @memberof SettingsManager
	 */
	static getSettingAsBoolean = (settingName: string, description: string, defaultValue: boolean, getFunction?: () => string): ISettingsManagerSetting<boolean> => {
		const setting = BaseSettingsManager.getSettingAsString(settingName, description, String(defaultValue))
		if (!['true', 'false'].includes(setting.value)) {
			throw new Error(`The setting [${settingName}] was requested as a boolean but was not boolean`)
		}
		return {
			name: settingName,
			defaultValue: defaultValue,
			value: setting.value.toLowerCase() === 'true',
			description: description,
		}
	}

	/**
	 * Load a setting in a number format.
	 *
	 * @param {string} settingName expected to be PascalCase format
	 * @param {string} description description for the setting (for display in debug panel)
	 * @param {number} [defaultValue] optional default value if setting not found. when not supplied a setting must be explicitly defined
	 * @returns {ISettingsManagerSetting}
	 * @memberof SettingsManager
	 */
	static getSettingAsNumber = (settingName: string, description: string, defaultValue: number, getFunction?: () => string): ISettingsManagerSetting<number> => {
		const setting = BaseSettingsManager.getSettingAsString(settingName, description, String(defaultValue))
		if (isNaN(parseInt(setting.value))) {
			throw new Error(`The setting [${settingName}] was requested as a number but was not number`)
		}
		return {
			name: settingName,
			defaultValue,
			value: parseInt(setting.value),
			description,
		}
	}

	static REACT_APP_MAIN_API_BASE_URL = (): ISettingsManagerSetting<string> => BaseSettingsManager.getSettingAsString('REACT_APP_MAIN_API_BASE_URL', 'The base url for the back-end api', 'https://---.---')
	static REACT_APP_IS_MAIN_API_MOCKED = (): ISettingsManagerSetting<boolean> => BaseSettingsManager.getSettingAsBoolean('REACT_APP_IS_MAIN_API_MOCKED', 'Determines if the back-end core API is mocked', false)
	static REACT_APP_USER_SESSION_API_BASE_URL = (): ISettingsManagerSetting<string> => BaseSettingsManager.getSettingAsString('REACT_APP_USER_SESSION_API_BASE_URL', 'Determines if the user-session back-end API is mocked', 'https://---.---')
	static REACT_APP_IS_USER_SESSION_API_MOCKED = (): ISettingsManagerSetting<boolean> => BaseSettingsManager.getSettingAsBoolean('REACT_APP_IS_USER_SESSION_API_MOCKED', 'Determines if the user-session back-end API is mocked', false)
	static REACT_APP_IS_DEBUG_PANEL_VISIBLE = (): ISettingsManagerSetting<boolean> => BaseSettingsManager.getSettingAsBoolean('REACT_APP_IS_DEBUG_PANEL_VISIBLE', 'Drives the visibility of the debug panel', false)
	static REACT_APP_IS_USER_SESSION_STORAGE_MOCKED = (): ISettingsManagerSetting<boolean> => BaseSettingsManager.getSettingAsBoolean('REACT_APP_IS_USER_SESSION_STORAGE_MOCKED', 'Determines if JWT storage is mocked', false)
	static REACT_APP_HARD_CODED_SESSION_JWT = (): ISettingsManagerSetting<string> => BaseSettingsManager.getSettingAsString('REACT_APP_HARD_CODED_SESSION_JWT', 'Optional hard-coding a user session to a specific JWT', '')
	static REACT_APP_HARD_CODED_SSO_JWT = (): ISettingsManagerSetting<string> => BaseSettingsManager.getSettingAsString('REACT_APP_HARD_CODED_SSO_JWT', 'Optional hard-coding an SSO token to a specific JWT', '')
	static REACT_APP_SECONDS_BEFORE_EXPIRATION_WARNING = (): ISettingsManagerSetting<number> => BaseSettingsManager.getSettingAsNumber('REACT_APP_SECONDS_BEFORE_EXPIRATION_WARNING', 'How many seconds before session expiration to show the warning popup?', 300)
	static REACT_APP_MYABILITY_ROOT_URL = (): ISettingsManagerSetting<string> => BaseSettingsManager.getSettingAsString('REACT_APP_MYABILITY_ROOT_URL', 'Location of core portal URL', '')
	static REACT_APP_INJECT_SSO_TOKEN = (): ISettingsManagerSetting<boolean> => BaseSettingsManager.getSettingAsBoolean('REACT_APP_INJECT_SSO_TOKEN', 'Determines if the SSO token should be added to local storage at start-up. This should only be set in development.', false)
	static REACT_APP_VERSION = (): ISettingsManagerSetting<string> => BaseSettingsManager.getSettingAsString('REACT_APP_VERSION', 'The version number of the app as defined in package.json', '??')
	static REACT_APP_TEXT_FIELD_VALIDATATION_REGEX = (): ISettingsManagerSetting<string> => BaseSettingsManager.getSettingAsString('REACT_APP_TEXT_FIELD_VALIDATATION_REGEX', 'Determine the text field valiation regex pattern', '')
	/**
	 * register all injectables to an ioc container.
	 * this should be called once and only once per container at the time of application bootstrap
	 *
	 * @export
	 */
	static configureContainer = (): void => {
		// note: there doesnt (yet) seem to be a mature/stable auto-wiring for services.
		// until one is available we will bind services explicitly in this method

		if (BaseSettingsManager.REACT_APP_IS_USER_SESSION_STORAGE_MOCKED().value) {
			container.bind<IJwtStorage>('IJwtStorage').to(HardCodedJwtStorage)
		} else {
			container.bind<IJwtStorage>('IJwtStorage').to(LocalJwtStorage)
		}

		if (BaseSettingsManager.REACT_APP_IS_USER_SESSION_API_MOCKED().value) {
			container.bind<IUserSessionApiClient>('IUserSessionApiClient').to(MockUserSessionApiClient)
		} else {
			container.bind<IUserSessionApiClient>('IUserSessionApiClient').to(UserSessionApiClient)
		}

		container
			.bind<IUserSessionStateManager>('IUserSessionStateManager')
			.to(UserSessionStateManager)
			.inSingletonScope()
		container.bind<IUserSessionMiddleware>('IUserSessionMiddleware').to(UserSessionMiddleware)
	}
}
