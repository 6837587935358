import { observable, action } from 'mobx'
import { injectable } from 'inversify'
import { UserSession } from './UserSession'
import { injectProperty } from '../../configuration/ServiceLocator'
import { IUserSessionStateManager } from './contracts'
import { IUserSessionMiddleware } from '../../middleware/UserSessionMiddleware/contracts'
import { SettingsManager } from '../../../configuration/SettingsManager'

@injectable()
export class UserSessionStateManager implements IUserSessionStateManager {
	@observable
	userSession: UserSession

	@injectProperty('IUserSessionMiddleware')
	userSessionMiddleware!: IUserSessionMiddleware

	constructor() {
		this.userSession = UserSession.getUserSessionNull()
	}

	@action loadSessionJwt(): Promise<void> {
		return this.userSessionMiddleware
			.loadSessionJwt()
			.then(sessionJwt => {
				const userSession = UserSession.getUserSessionFromJwt(sessionJwt)
				this.setUserSession(userSession)
			})
			.catch(error => {
				console.error(`error encountered in UserSessionMiddleware: ${JSON.stringify(error)}`)
				this.setUserSession(UserSession.getUserSessionNull())
			})
	}

	@action setUserSession(userSession: UserSession): void {
		this.userSession = userSession
	}

	@action simulateTokenNearExpiration() {
		this.setUserSession(UserSession.getUserSessionNearExpiration())
	}

	@action simulateTokenExpired() {
		this.setUserSession(UserSession.getUserSessionExpired())
	}

	@action logOut(): void {
		localStorage.removeItem(SettingsManager.REACT_APP_SESSION_TOKEN_NAME)
		if (window.location.href.indexOf(SettingsManager.REACT_APP_IFRAME_PAGE_URLS) > -1) {
			window.top.location.href = '/Auth/Logout'
		} else {
			window.location.href = '/Auth/Logout'
		}
	}
}
