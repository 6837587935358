import { injectable } from 'inversify'
import { SettingsManager } from '../../../configuration/SettingsManager'
import { BaseJwtStorage } from './BaseJwtStorage'

/**
 * implementation of jwt storage which uses a hardcoded configuration-defined jwt value
 *
 * @export
 * @class HardCodedJwtStorage
 * @extends {BaseJwtStorage}
 */
@injectable()
export class HardCodedJwtStorage extends BaseJwtStorage {
	public readonly saveJwt = (): void => {}
	public readonly clear = (): void => {}
	public readonly getJwt = (): string | null => SettingsManager.REACT_APP_HARD_CODED_SESSION_JWT().value
}
