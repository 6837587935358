import 'reflect-metadata'

export class SessionTokenStaleError extends Error {}
export class SessionTokenTokenNbfInFutureError extends Error {}
export class SessionTokenExpiredError extends Error {}
export class SessionTokenMissingError extends Error {}

/**
 * represents local storage for user sessions
 *
 * @export
 * @interface IJwtStorage
 */
export interface IJwtStorage {
	saveJwt(sessionJwt: string): void
	clear(): void
	getJwt(): string | null
}
